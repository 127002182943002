export function htmlToText(str: string): string {
  return str.replace(/<[^>]*>/g, '');
}

export function upperCaseFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function upperCaseFromUnderscore(str: string): string {
  return str
    .split('_')
    .map(w => upperCaseFirstLetter(w))
    .join(' ');
}

export function sanitizeInput(value: string): string {
  return value.replace(/[^\w]/g, '').toLowerCase().trim();
}
